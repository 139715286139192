import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Store } from '../types';

const SUPERADMIN = 'ROLE_SUPERADMIN';
const ADMIN = 'ROLE_ADMIN';

const useRole = () => {
  const { roles } = useSelector((state: Store) => state.auth);

  const { isSuperAdmin, isAdmin } = useMemo(
    () => ({
      isSuperAdmin: roles?.includes(SUPERADMIN),
      isAdmin: roles?.includes(ADMIN),
    }),
    [roles],
  );

  return { isSuperAdmin, isAdmin };
};

export default useRole;
