import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import YouTubeIcon from '@mui/icons-material/YouTube';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';

const linkStyles = {
  color: 'inherit',
  textDecoration: 'inherit',
};

type Props = {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  isSuperAdmin: boolean;
};

export const ListItems: React.FC<Props> = ({ selectedIndex, setSelectedIndex, isSuperAdmin }) => {
  const { t } = useTranslation();

  return (
    <List style={{ flexGrow: 1 }}>
      <Link to="/dashboard/news" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(0);
          }}
          selected={selectedIndex === 0}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <NewspaperIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.news')} />
        </ListItem>
      </Link>

      <Link to="/dashboard/courses" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(1);
          }}
          selected={selectedIndex === 1}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.courses')} />
        </ListItem>
      </Link>
      <Link to="/dashboard/about-patricia" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(4);
          }}
          selected={selectedIndex === 4}
          button>
          <ListItemIcon>
            <CoPresentIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.patriciaIntroduction')} />
        </ListItem>
      </Link>
      <Link to="/dashboard/about-hec" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(5);
          }}
          selected={selectedIndex === 5}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <AutoGraphIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.highEnergySecrets')} />
        </ListItem>
      </Link>
      <Link to="/dashboard/youtube" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(2);
          }}
          selected={selectedIndex === 2}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <YouTubeIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.youtube')} />
        </ListItem>
      </Link>
      <Link to="/dashboard/notifications" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(3);
          }}
          selected={selectedIndex === 3}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.notifications')} />
        </ListItem>
      </Link>
      <Link to="/dashboard/lifestyle" style={linkStyles}>
        <ListItem
          onClick={() => {
            setSelectedIndex(7);
          }}
          selected={selectedIndex === 7}
          button>
          <ListItemIcon sx={{ minWidth: 55 }}>
            <FavoriteBorderIcon />
          </ListItemIcon>
          <ListItemText primary={t('dashboard.lifestyle')} />
        </ListItem>
      </Link>
      {isSuperAdmin && (
        <Link to="/dashboard/user-management" style={linkStyles}>
          <ListItem
            onClick={() => {
              setSelectedIndex(6);
            }}
            selected={selectedIndex === 6}
            button>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={t('dashboard.users')} />
          </ListItem>
        </Link>
      )}
    </List>
  );
};
