import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from 'src/translations/en.json';
import DE from 'src/translations/de.json';
import { APP_LANG_NAME, LANGUAGES } from 'src//constants/constants';
import { AppLocalStorage } from '../../helpers';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EN },
    de: { translation: DE },
  },
  lng:
    (AppLocalStorage.getItem({ key: APP_LANG_NAME }) || '').toLowerCase() ||
    LANGUAGES.EN.toLowerCase(),
  fallbackLng: false,

  interpolation: {
    escapeValue: false,
  },

  react: {
    useSuspense: false,
  },
});

export default i18n;
