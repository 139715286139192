import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { User } from 'src/types/entities';
import { ROLES } from 'src/constants/constants';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Loading } from 'src/components/Loading';
import { useSelector } from 'react-redux';
import { Store } from 'src/types';

const styles = {
  buttonContainer: {
    width: 100,
    height: '50px',
    alignSelf: 'center',
  },
  button: {
    marginRight: '10px',
  },
  image: {
    width: 200,
    height: 100,
    objectFit: 'contain',
    objectPosition: 'left',
  } as React.CSSProperties,
};

interface UsersProps {
  users: User[];
  loading: boolean;
  onDelete: (id: number) => void;
  onEdit: (username: string) => void;
}

const UsersTable: FC<UsersProps> = ({ users, loading, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const { searchUsers } = useSelector((state: Store) => state.users);

  const showDeleteButton = !searchUsers.length;

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('users.firstName')}</TableCell>
            <TableCell>{t('users.lastName')}</TableCell>
            <TableCell>{t('users.email')}</TableCell>
            <TableCell>{t('users.role')}</TableCell>
            <TableCell>{t('users.image')}</TableCell>
            <TableCell>{t('users.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.firstName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.lastName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell>
                {row.authorities.map((item) => (
                  <div>{ROLES[item]}</div>
                ))}
              </TableCell>
              <TableCell>
                {row.imageUrl ? (
                  <img src={row.imageUrl} style={styles.image} alt="uploaded" />
                ) : (
                  'No image uploaded'
                )}
              </TableCell>
              <TableCell>
                <Box sx={styles.buttonContainer}>
                  <IconButton
                    sx={styles.button}
                    color="primary"
                    onClick={() => {
                      onEdit(row.username);
                    }}>
                    <EditIcon />
                  </IconButton>
                  {showDeleteButton && (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        onDelete(row.id);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
